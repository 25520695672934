import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types';
import Head from 'next/head'
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router'
import useFetch from './utils/ApiHooks';
const Alert = dynamic(() => import('./Alert/Alert'))
import {homepageurl, defaultLang, loginformurl, loginAPI, inputFieldValidatiMsgObj, loginFieldObj, accountLockedDuration, loginMaxTry, backgroundMusic, defaultCountry} from './utils/constant'
import {PokemonImages} from '../../adapters/helpers/Constants'
const AddBrush = dynamic(() => import('./AddBrush/AddBrush'))
const Footer = dynamic(() => import('./Footer/Footer'))
const ForgotPassword = dynamic(() => import('./ForgotPassword/ForgotPassword'))
import { customJsonstringify } from '../../adapters/helpers/Utils';
import { optimizeImage } from '../../adapters/helpers/pagespeedHelper';
const GTMScriptFunction = dynamic(() => import('../GTMScriptFunction'));

function Homepage(props) {
    const { lang,settingsdata } = props
    const [playmusic, setPlaymusic] = useState(null)
    const [isAlreadyLogedin, setisAlreadyLogedin] = useState(false)
    const [errorMessage,setErrorMessage] = useState(null)
    const [lockedAccountMsg, setLockedAccountMsg] = useState(null)
    const [count, setCount] = useState(0)
    const [BackgroundImage,setBackgroundImage] = useState(null)
    const [emailFieldMsg, setEmailFieldMsg] = useState(null);
    const [passwordFieldMsg, setPasswordFieldMsg] = useState(null);
    const [showNoteAlert,setShowNoteAlert] = useState(null)
    const [isLoggedIn,setIsLoggedIn] = useState(null)
    const [isForgotPassword, setIsForgotPassword] = useState(false)
    let internalUrl = process.env.POKEMON_API_URL
    let apiurl = `${internalUrl}${homepageurl}${lang ? lang : defaultLang}`
    const router = useRouter()
    const [homePageData] = useFetch(apiurl, lang)
    const [loginform] = useFetch(`${internalUrl}${loginformurl}${lang ? lang : defaultLang}`, lang)
    const mailref = useRef()
    const passwordref = useRef()
    const audioref = useRef();

    const GRSFormData = loginform && loginform.GRSFormData
    const inputFieldValMsgObj = inputFieldValidatiMsgObj(lang);
    const loginFieldMsgObj = loginFieldObj(lang);

    function handleSetAudioCurrentTime(navigateURL, browserURL) {
        if(localStorage.getItem('music') != 'false'){
            let getAudio = document.getElementById('pokemon-default-music');
            localStorage.setItem('audioCurrentTime', getAudio.currentTime);
        }
        router.push(navigateURL, browserURL, { shallow: true });
    };

    useEffect(() => {
        setBackgroundImage(PokemonImages.kidsBackground)
        if(localStorage.getItem('AccessTokenResponse')) {
            handleSetAudioCurrentTime('', '/ja-jp/brushingmaster/add-brush');
            setIsLoggedIn(true);
            localStorage.setItem('NoteAlert',true)
            setisAlreadyLogedin(true)
        }else{
            setisAlreadyLogedin(false)
        }

        if(localStorage.getItem('language') && !localStorage.getItem('Parentuuid') && localStorage.getItem('logout') != 'true'){
            setShowNoteAlert(true);
        }else{
            localStorage.setItem('logout',null)
        }        
    }, [])


    const showSettings = () => {
        localStorage.setItem('logout',true)
        handleSetAudioCurrentTime('/brushingmastersettings', '/ja-jp/brushingmaster/settings');        
    }

    const goToForgotPassowrd = (event) => {
        event.preventDefault();
        localStorage.setItem('logout',true)
        setIsForgotPassword(true);
        handleSetAudioCurrentTime('', '/ja-jp/brushingmaster/forgot-password');
    }

    const comeBackFromFrogotPassword = () =>{
        setIsForgotPassword(false);
        router.push('', '/ja-jp/brushingmaster', { shallow: true })       
    }

    const goToRegister = (event) => {
        event.preventDefault();
        localStorage.setItem('logout',true)
        handleSetAudioCurrentTime('/brushingmasterregister', '/ja-jp/brushingmaster/register');
    }

    const userEmailChange = (event, obj) => {        
        if (event.target.value == "" || event.target.value == null) {
            setEmailFieldMsg(obj?.value?.validationMandatoryError);
        }
        else {
            setEmailFieldMsg(null);
        }
    };

    const userPasswordChange = (event, obj) => {        
        if (event.target.value == "" || event.target.value == null) {
            setPasswordFieldMsg(obj?.value?.validationMandatoryError);
        }
        else {
            setPasswordFieldMsg(null);
        }
    };

    const loginrequest  = async () => {
        let localLoginTryCount = parseInt(localStorage.getItem('loginTryCount'));
        let localLoginBlockTimeStamp = Number(localStorage.getItem('loginBlockTimeStamp'));
        let tryingTimeStamp = Date.now();
        
        if(localLoginTryCount >= loginMaxTry &&  (tryingTimeStamp - localLoginBlockTimeStamp) < accountLockedDuration)
        {
            setLockedAccountMsg(loginFieldMsgObj.accountLocked)
            return false;
        }

        let validation = true;
        if(mailref.current.value== "" || mailref.current.value == null || mailref.current.value == undefined) {
            setEmailFieldMsg(inputFieldValMsgObj.mandatoryCheckMsg);
            validation = false;
        }
        if(passwordref.current.value== "" || passwordref.current.value == null || mailref.current.value == undefined) {
            setPasswordFieldMsg(inputFieldValMsgObj.mandatoryCheckMsg);
            validation = false;
        }

        if(validation) {
            localStorage.removeItem('loginTryCount')
            localStorage.removeItem('loginBlockTimeStamp')

            await fetch(`${internalUrl}${loginAPI}${lang}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': process.env.BASE_URL,
                "Ocp-Apim-Subscription-Key": process.env.EAPIM_SUBSCRIPTION_KEY,
                "Authorization": localStorage.getItem('TempEAPIMAuthKey')
            },
            body: customJsonstringify({ dictonary: { signInEmailAddress: mailref.current.value, currentPassword: passwordref.current.value } })
        }).then((response)=>{
            return response.json()
        }).then((data)=>{
            let curTimeStamp = Date.now();
            if(data.StatResponse === 'ok'){
                localStorage.setItem('NoteAlert',true)
                localStorage.setItem('back', true)
                localStorage.setItem('AccessTokenResponse',data.AccessTokenResponse)
                localStorage.setItem('Parentuuid',data.Parentuuid)
                localStorage.setItem('RefreshTokenResponse',data.RefreshTokenResponse)
                localStorage.setItem('LastActiveTimeStamp', curTimeStamp)
                handleSetAudioCurrentTime('', '/ja-jp/brushingmaster/add-brush');
                setIsLoggedIn(true);
            }else{
                let loginTryCount= count + 1;
                setCount(loginTryCount);
                if(count >= loginMaxTry){
                    localStorage.setItem('loginTryCount', count);
                    localStorage.setItem('loginBlockTimeStamp', curTimeStamp);
                    setCount(0);
                }
                setErrorMessage(loginFieldMsgObj.loginFailedMsg)
            }
        }).catch(()=>{
            setErrorMessage('API failed')
        });
        }
    }
    const Headdata = homePageData?.HomePageData?.Head
    const Body = homePageData?.HomePageData?.Body
    const MidSection = Body?.MidSection
    const Logo = Body?.Logo
    const loginSection = Body?.LoginSection
    let NotData = settingsdata?.SettingsData?.NoteText

    const callbackAlert = ()=>{
        setErrorMessage(null)
    }

    const accountLockedCallback = ()=>{
        setLockedAccountMsg(null)
    }

    const hideNoteAlert = () =>{
        localStorage.setItem('NoteAlert',true)
        setShowNoteAlert(false)
        
        if (localStorage.getItem('music') != 'false') {
            setPlaymusic(true)
        }
    }

    useEffect(() => {
        if(localStorage.getItem('music') != 'false' && (localStorage.getItem('NoteAlert') == 'true' || localStorage.getItem('back') == 'true')){
            const getAudioTime = localStorage.getItem('audioCurrentTime')
            const getAudio = document.getElementById('pokemon-default-music')
            getAudio.currentTime = getAudioTime
            getAudio.play()
        }
    }, [])

    return (
        <React.Fragment>
            <div>
                <audio id='pokemon-default-music' controls loop hidden ref={audioref}>
                    <source src={backgroundMusic} type="audio/mpeg">
                    </source>
                </audio>
                {homePageData && !isLoggedIn && !isForgotPassword && <><div className={'Homepage '+lang}>
                    <Head>
                        <title>{Headdata && Headdata.MetaTitle}</title>
                    </Head>
                    {<div className='Homepage-container' style={{backgroundImage: `url('${BackgroundImage}')`}}>
                        <div className='Homepage-container-right-headermobile'>
                            <img className='Homepage-container-right-headermobile-image' src={optimizeImage(PokemonImages.brushingtitle)} alt={MidSection?.Headline1DesktopImage.Alt} />
                            <div>
                                <img className='Homepage-container-right-headermobile-settings' onClick={() => { showSettings() }} src={optimizeImage(PokemonImages.settingIcon)} alt='Settings Icon' />
                            </div>
                        </div>

                        <div className='Homepage-container-left'>
                            <div className='Homepage-container-left-logocontainer'>
                                <img className='Homepage-container-left-logocontainer-logo' src={optimizeImage(PokemonImages.oralblogo)} alt={Logo?.AltText} />
                            </div>
                            <div className='Homepage-container-left-formcontainer'>
                                <form className='Homepage-container-left-formcontainer-form'>
                                    {GRSFormData && GRSFormData.map((value, key) => {
                                        return (value.type === 'text' || value.type === 'password') ? <><input key={key} type={value.type}  onChange={(event) => (value.type === 'text' ? userEmailChange(event, {value}) : userPasswordChange(event, {value}) )} ref={value.type === 'text' ? mailref : passwordref} dataerror={value.validationMandatoryError} className='Homepage-container-left-formcontainer-form-inputbox' placeholder={value && value.labelTranslated + "*"}></input>{emailFieldMsg && value.type === 'text' && <span className='error'>{emailFieldMsg}</span>}{passwordFieldMsg && value.type === 'password' && <span className='error'>{passwordFieldMsg}</span>} </>: <button key={key} onClick={() => { loginrequest() }} type='button' className='Homepage-container-left-formcontainer-form-button'>{value && value.labelTranslated}</button>
                                    })}
                                </form>
                            </div>
                            <div className='Homepage-container-left-linkcontainer'>
                                <div className='Homepage-container-left-linkcontainer-forgotpassword'>
                                    <a onClick={(e) => { goToForgotPassowrd(e) }} className='Homepage-container-left-linkcontainer-forgotpassword-anchor'>{loginSection && loginSection.ForgotPasswordText}</a>
                                </div>
                                <div className='Homepage-container-left-linkcontainer-register'>
                                    <span className='Homepage-container-left-linkcontainer-register-span'>{loginSection?.NewUserText}</span>
                                    <a tabIndex={0} onClick={(e) => { goToRegister(e) }} className='Homepage-container-left-linkcontainer-register-registeranchor'>{loginSection?.ClickHereText}</a>
                                </div>
                            </div>
                        </div>
                        <div className='Homepage-container-right'>
                            <div className='Homepage-container-right-header'>
                                <img className='Homepage-container-right-header-image' src={PokemonImages.brushingtitle} alt={MidSection?.Headline1DesktopImage.Alt} />
                                <div>
                                    <img className='Homepage-container-right-header-settings' onClick={() => { showSettings() }} src={optimizeImage (PokemonImages.settingIcon)} alt='Settings Icon' />
                                </div>
                            </div>
                            <div className='Homepage-container-right-body'>
                                <img className='Homepage-container-right-body-image-kids' src={optimizeImage(PokemonImages.kidsHomePageChar)} alt={MidSection?.ForegroundImageAlt} />
                            </div>
                            <div></div>
                        </div>
                    </div>}
                </div>
                <Footer lang={lang} />
                {showNoteAlert && NotData && <Alert message={NotData} callbackfunction={hideNoteAlert}/>}
                {errorMessage && <Alert message={errorMessage} callbackfunction={callbackAlert} />}
                {lockedAccountMsg && <Alert message={lockedAccountMsg} callbackfunction={accountLockedCallback} />}
                </>            
                }
                {isLoggedIn && <AddBrush />}
                {isForgotPassword && <ForgotPassword backClick={comeBackFromFrogotPassword}/>}
            </div>
            <GTMScriptFunction languageCode={lang ? lang : defaultLang} countryCode={defaultCountry} />
        </React.Fragment>
    )
}

Homepage.propTypes = {
    lang: PropTypes.any,
    settingsdata: PropTypes.any
}

export default Homepage